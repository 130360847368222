<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import Gen from '@helper/Gen';
  import draggable from 'vuedraggable'

  export default {
    name: "BoAbout",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoAbout",
        back: {},
        overview_recipe: {},
        overview_shop: {},
        overview_kesehatan: {},
        row: {},
        show: {},
        hilo: {},
        data: [],
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData(() => {
        setTimeout(() => {
          this.$set(this.row, 'type', 'update')
        }, 500)
      })
    },
    methods: {
      submitBackground() {
        BOGen.apirest('/' + this.Name, {
          data: this.back,
          type: 'updateBackground'
        }, (err, resp) => {
          if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.back-info')
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, '.back-info')
            this.refreshData()
            setTimeout(() => {
              $('#collapseExample').hide()
            }, 3500)
          }
        }, "POST");
      },
      submitGoodness(e) {
        if(e&&e.btnLoading()) return;
        BOGen.apirest('/' + this.Name, this.row, (err, resp) => {
          if(e) e.btnUnloading()
          if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.col-info')
          if (resp.success) {
            Gen.info(resp.message, 'success', 2000, '.col-info')
            this.refreshData()
            setTimeout(() => {
              $('#collapseExample').hide()
            }, 2000)
          }
        }, "POST");
      },
      submitShow(e) {
        if(e&&e.btnLoading()) return;
        this.show.type ="updateShowProduct"
        BOGen.apirest('/' + this.Name, this.show, (err, resp) => {
          if(e) e.btnUnloading()
          if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.show-info')
          if (resp.success) {
            Gen.info(resp.message, 'success', 2000, '.show-info')
            this.refreshData()
          }
        }, "POST");
      },
      submitAbout(e) {
        if(e&&e.btnLoading()) return;
        this.hilo.type ="updateAbout"
        BOGen.apirest('/' + this.Name, this.hilo, (err, resp) => {
          if(e) e.btnUnloading()
          if (resp.error) return Gen.info(resp.message, 'danger', 3000, '.about-info')
          if (resp.success) {
            Gen.info(resp.message, 'success', 2000, '.about-info')
            this.refreshData()
          }
        }, "POST");
      },
      addSlide() {
        this.row = {
          ag_is_active: "Y"
        }
        this.row.type = 'add'
      },
      editSlide(v) {
        $('#collapseExample').css("display", "flex")
        this.row = v;
        this.row.type = 'update'
      },
      endDrag() {
        BOGen.apirest('/' + this.Name, {
          data: this.data.data,
          type: 'sort'
        }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
    },
    watch: {
      '$route.query'(v) {
        this.refreshData()
      }, 
    }
  }
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Background Image</h5>
        </div>

        <div class="card-body">
          <VForm @resp="submitBackground" method="post">
            <div class="back-info"></div>
            <div class="row">
              <div class="col-md-3">
                <BoField name="background1" mandatory :label="'Left Background'" class="mb-0">
                  <Uploader :param="{thumbnail:false}" name="background1" v-model="back.background1"
                    type="backabout" uploadType="cropping"></Uploader>
                </BoField>
              </div>
              <div class="col-md-3">
                <BoField name="background2" mandatory :label="'Right Background'" class="mb-0">
                  <Uploader :param="{thumbnail:false}" name="background2" v-model="back.background2"
                    type="backabout" uploadType="cropping"></Uploader>
                </BoField>
              </div>
              <div class="col-md-6">
                <label for="">Parallax Background Preview</label>
                <div class="bg-preview bg-preview--bg-x-lr" :style="'background-image:url('+uploader(back.background1)+'), url('+uploader(back.background2)+')'"></div>
              </div>
            </div>
            <hr>
            <div class="text-right">
              <button type="submit" class="btn btn-rounded btn-info btn-loading">Update Background</button>
            </div>
          </VForm>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h5 class="card-title">About HiLo</h5>
        </div>

        <div class="card-body">
          <VForm @resp="submitAbout" method="post">
            <div class="about-info"></div>
            <div class="row">
              <div class="col-md-6">
                <BoField name="as_name" mandatory :label="'Title'" class="mb-0" v-model="hilo.as_name"></BoField>
              </div>
              <div class="col-md-12 mt-3">
                <BoField name="as_val" mandatory>
                  <CKEditor name="as_val" v-model="hilo.as_val" v-bind="validation('as_val')"></CKEditor>
                </BoField>   
              </div>
            </div>
            <hr>
            <div class="text-right">
              <button type="submit" class="btn btn-rounded btn-info btn-loading">Update Content</button>
            </div>
          </VForm>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Kebaikan Susu HiLo</h5>
        </div>
        <div class="card-body">
          <draggable v-model="data.data" class="row gutter-20" @end="endDrag">
          <div style="cursor:move;" class="col-md-3 col-lg-20" :id="'dat'+v.ag_id" v-for="(v,k) in data.data" :key="k">
            <div class="wrap_slider_img">
              <img :src="uploader(v.ag_img)" class="img-responsive" />
              <div class="slider_name">
                <p>{{v.ag_title}}</p>
              </div>
              <a data-toggle="collapse" @click="editSlide(v)" href="javascript:;" class="bullet_edit"><i
                  class="ti-marker-alt"></i></a>
              <a href="javascript:;" @click="deleteItemId(v.ag_id,'dat'+v.ag_id)" class="bullet_delete"><i
                  class="far fa-trash-alt"></i></a>
              <span class="label label-success" v-if="v.ag_is_active == 'Y'">Active</span>
              <span class="label label-danger" v-else>Inactive</span>
            </div>
          </div>

          <div class="col-md-3 col-lg-20" :class="data.data.length > 3 ? 'mt-3':''">
            <a class="wrap_upload" data-toggle="collapse" @click="addSlide" href="#collapseExample" role="button"
              aria-expanded="false" aria-controls="collapseExample">
              <div class="ic_wrap">
                <i class="fas fa-plus"></i>
                <p>Add New</p>
              </div>
            </a>
          </div>
          </draggable>
            <div class="row collapse mt-4" id="collapseExample">
              <div class="col-md-4 mb-3">
                <BoField name="ag_img" mandatory>
                  <Uploader name="ag_img" :param="{thumbnail:true}" type="goodness" uploadType="cropping" v-model="row.ag_img"></Uploader>
                </BoField>
              </div>
              <div class="col-md-8 mb-3">
                <VForm @resp="submitGoodness" method="post">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="panel_group">
                        <div class="panel_heading">Kebaikan Susu HiLo</div>
                        <div class="panel_body">
                          <div class="row">
                            <div class="col-12 col-info"></div>
                            <div class="col-md-6">
                              <BoField name="ag_title" :attr="{maxlength:'100',minlength:'3',required:'required'}" v-model="row.ag_title" mandatory></BoField>
                            </div>
                            <div class="col-md-12">
                              <BoField name="ag_desc" mandatory >
                                <CKEditor name="ag_desc" v-model="row.ag_desc" v-bind="validation('ag_desc')"></CKEditor>
                              </BoField>
                            </div>
                            <div class="col-sm-4">
                              <BoField name="ag_is_active">
                                <radio name="ag_is_active" v-model="row.ag_is_active" option="Y" :attr="validation('ag_is_active')">Active</radio>
                                <radio name="ag_is_active" v-model="row.ag_is_active" option="N">Inactive</radio>
                              </BoField>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-3 text-right">
                      <button type="submit"
                        class="btn btn-rounded btn-loading btn-info">{{row.ag_id ? "Update":"Add New"}}</button>
                    </div>
                  </div>
                </VForm>
              </div>
            </div>
          </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="show-info"></div>
          <div class="row mx-0 align-items-center">
            <h5 class="card-title mr-4 mb-2 mb-md-0">Show HiLo Brand List?</h5>
            <VForm @resp="submitShow" class="row mx-0 align-items-center">
              <BoField name="as_val" :labelText="false" class="mb-0">
                <radio name="as_val" v-model="show.as_val" option="Y" :attr="validation('as_val')">Yes</radio>
                <radio name="as_val" v-model="show.as_val" option="N">No</radio>
              </BoField>
              <button type="submit" class="fcbtn btn btn-info btn-outline btn-rounded btn-1e btn-loading">Update <i class="icon-arrow-right14 position-right"></i></button>
            </VForm>
          </div>
        </div>
      </div>
  </div>
</template>